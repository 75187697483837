import { I18n } from '@front/volcanion'

class Callbacks {
  static onConnectHandler(listener, openNotification, setToggleMode, toggleMode, inputPhone, selectedEndPoint) {
    return async function onConnect() {
      try {
        if (toggleMode === 'phone') {
          await listener.connectByPhone(inputPhone)
          setToggleMode(null)
        }
        else {
          await listener.connectByEndpoint(selectedEndPoint)
          setToggleMode(null)
        }
      }
      catch (err) {
        openNotification(I18n.t('ivr.error.call'), { variant: 'error' })
      }
    }
  }

  static onTogglePhoneChangeHandler(setInputPhone, setSelectedEndPoint, setToggleMode, inputPhone, selectedEndPoint, toggleMode) {
    return function onTogglePhoneChange() {
      if (!!selectedEndPoint)
        setSelectedEndPoint(null)
      if (!!inputPhone)
        setInputPhone(null)

      const new_value = toggleMode === 'phone' ? null : 'phone'
      setToggleMode(new_value)
    }
  }

  static onToggleContactChangeHandler(setSelectedEndPoint, setInputPhone, setToggleMode, selectedEndPoint, inputPhone, toggleMode) {
    return function onToggleContactChange() {
      if (!!selectedEndPoint)
        setSelectedEndPoint(null)
      if (!!inputPhone)
        setInputPhone(null)

      const new_value = toggleMode === 'contact' ? null : 'contact'
      setToggleMode(new_value)
    }
  }
}

export default Callbacks
