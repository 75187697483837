import React from 'react'

import { Box, IconButton, Icon, Typography } from '@mui/material'

const IVRMiddle = (props) => {
  const {
    isConference,
    onToggleActiveConnection,
    onConferenceConnection,
    onEndAgentConnection
  } = props

  return (
    <Box m={1} flexShrink={0} sx={{ display: 'flex', alignItems: 'center' }}>
      {
        !isConference &&
        <>
          <IconButton aria-label="ivr" style={{ color: 'white' }} onClick={onToggleActiveConnection} >
            <Icon>{'swap_horiz'}</Icon>
          </IconButton>
          <IconButton aria-label="ivr" style={{ color: 'white' }} onClick={onConferenceConnection} >
            <Icon>{'merge'}</Icon>
          </IconButton>
        </>
      }
      <IconButton aria-label="ivr" style={{ color: 'white' }} onClick={onEndAgentConnection} >
        <Icon>{'phone_disabled'}</Icon>
      </IconButton>
      <Typography variant={'h4'} sx={{ ml: 1, color: 'white' }}> | </Typography>
    </Box>
  )

}

export default React.memo(IVRMiddle)
