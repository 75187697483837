import React, { useCallback } from 'react'

import { hooks } from '@front/volcanion'
import { TimeUtils } from '@front/volcanion/utils'

import { useListener, useListenerSelector } from '@front/squirtle/IVR/hooks'

import Component from './component'
import Callbacks from './callbacks'

const Container = ({ isThirdConnection }) => {
  const { openNotification } = hooks.useNotification()
  const listener = useListener('ivr', 'ivr', 'basic')
  const [isConnectionOnHold, callerPhone, connectionStatus] = useListenerSelector('ivr', 'ivr', 'basic',
    useCallback((l) => [
      l.isConnectionOnHold(isThirdConnection),
      l.getPhoneNumber(isThirdConnection),
      l.getConnectionStatus(isThirdConnection)
    ], [isThirdConnection]))

  const holdColor = !!isConnectionOnHold ? 'red' : 'white'
  const pinColor = !!isConnectionOnHold ? 'red' : 'green'
  const connectionStartTime = connectionStatus?.timestamp

  const onEndCall = useCallback(Callbacks.onEndCallHandler(listener, openNotification, isThirdConnection), [listener, openNotification, isThirdConnection])
  const onHold = useCallback(Callbacks.onHoldHandler(listener, openNotification, isConnectionOnHold, isThirdConnection),
    [listener, openNotification, isConnectionOnHold, isThirdConnection])
  const onMutateTimer = useCallback((value) => TimeUtils.secondsToTime(value, 'mm:ss'), [TimeUtils.secondsToTime])

  const mergedProps = {
    onHold,
    holdColor,
    pinColor,
    onEndCall,
    callerPhone,
    connectionStartTime,
    onMutateTimer
  }
  return <Component {...mergedProps} />

}

export default Container
