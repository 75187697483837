import React from 'react'
import { Box, IconButton, Icon, Typography, Grid } from '@mui/material'

import { TimerSinceComponent } from '@front/volcanion'

const IVRCall = (props) => {
  const {
    onHold,
    holdColor,
    pinColor,
    onEndCall,
    callerPhone,
    connectionStartTime,
    onMutateTimer
  } = props

  return (
    <Box m={1} flexShrink={0} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      <Grid sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
        <Icon sx={{ fontSize: 20, color: pinColor }}>{'circle'}</Icon>
        <TimerSinceComponent startTime={connectionStartTime} mutator={onMutateTimer} />
        <Box>{callerPhone}</Box>
      </Grid>
      <Grid>
        <IconButton aria-label="ivr" style={{ color: holdColor }} onClick={onHold} >
          <Icon>{'phone_paused'}</Icon>
        </IconButton>
      </Grid>
      <Grid>
        <IconButton aria-label="ivr" style={{ color: 'white' }} onClick={onEndCall} >
          <Icon>{'phone_disabled'}</Icon>
        </IconButton>
      </Grid>
      <Typography variant={'h4'} sx={{ color: 'white' }}> | </Typography>
    </Box>
  )

}

export default React.memo(IVRCall)
