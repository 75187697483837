import { I18n } from '@front/volcanion'

class Callbacks {
  static onEndCallHandler(listener, openNotification, isThirdConnection) {
    return async function onEndCall() {
      try {
        await listener.endCall(isThirdConnection)
      }
      catch (err) {
        openNotification(I18n.t('ivr.error.endCall'), { variant: 'error' })
      }
    }
  }

  static onHoldHandler(listener, openNotification, isConnectionOnHold, isThirdConnection) {
    return async function onHold() {
      try {
        if (isConnectionOnHold)
          await listener.setConnectionResumeStatus(isThirdConnection)
        else
          await listener.setConnectionHoldStatus(isThirdConnection)
      }
      catch (err) {
        openNotification(I18n.t('ivr.error.onHold'), { variant: 'error' })
      }
    }
  }
}

export default Callbacks
