import React from 'react'

import { Grid, Typography, Icon, IconButton } from '@mui/material'

import { AgentInfoComponent, IncomingCallComponent, CallComponent, MiddleComponent, ContactComponent } from './components'

const IvrBar = (props) => {
  const {
    canDisplayIconConnectButton,
    canDisplayIvrComponents,
    agentStatus,
    isMute,
    onMute,
    hasActiveFirstConnection,
    hasThirdPartyConnection,
    isAutoAccept,
    onClick
  } = props

  return (
    <Grid container spacing={1} alignItems='center' sx={{ height: 1 }}>
      <Grid item xs>
        {!!canDisplayIvrComponents &&
          <Typography color="inherit" sx={{ display: 'flex', alignItems: 'center' }} variant="subtitle1" gutterBottom>
            <AgentInfoComponent />
            {agentStatus?.name === 'Busy' &&
              <IconButton aria-label="ivr" style={{ color: isMute ? 'red' : 'white' }} onClick={onMute} >
                <Icon >{isMute ? 'mic_off' : 'mic'}</Icon>
              </IconButton>
            }
            {!isAutoAccept && agentStatus?.name === 'PendingBusy' &&
              <IncomingCallComponent />
            }
            {hasActiveFirstConnection &&
              <CallComponent />
            }
            {hasActiveFirstConnection && hasThirdPartyConnection &&
              <MiddleComponent />
            }
            {hasThirdPartyConnection &&
              <CallComponent isThirdConnection={true} />
            }
            <ContactComponent />
          </Typography>
        }
        {!!canDisplayIconConnectButton &&
          <IconButton aria-label="ivr" style={{ color: 'white' }} onClick={onClick} >
            <Icon >{'account_box'}</Icon>
          </IconButton>
        }
      </Grid>
    </Grid>
  )
}

export default React.memo(IvrBar)
