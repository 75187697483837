import React from 'react'


import { Box, Icon } from '@mui/material'
import { MenuSelectorComponent } from '@front/squirtle'
import { I18n, TimerSinceComponent } from '@front/volcanion'

const IVRAgentInfo = (props) => {
  const {
    agentName,
    agentStatus,
    agentStatusList,
    iconColor,
    onAgentStatusChange,
    onMutateTimer
  } = props

  return (
    <>
      <Box mr={2}>{I18n.t('ivr.title', { name: agentName })}</Box>
      <Icon aria-label='ivr' sx={{ color: iconColor, margin: '8px' }} >{'headset_mic'}</Icon>
      <Box m={1} flexShrink={0}>
        <MenuSelectorComponent
          name='agent_status'
          options={_.map(_.uniqBy(_.compact(_.flatten([agentStatusList, [agentStatus]])), 'name'), (state) => ({
            label: state.type === 'system' || state.type === 'error' ? I18n.t(`ivr.status.${state.name}`) : state.name,
            value: state.name
          }))}
          onChange={onAgentStatusChange}
          value={agentStatus?.nextState?.name || agentStatus?.name}
          variant='outlined'
          sx={{
            px: 1,
            '.MuiSvgIcon-root': {
              color: 'white'
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: 'white'
            },
            '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: 'white'
            },
            '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: 'white'
            }
          }}
          valueDecoratorProps={{
            color: 'white'
          }}
        />
      </Box>
      {agentStatus?.type === 'not_routable' && <Icon sx={{ color: iconColor }} >{'timer'}</Icon>}
      {agentStatus?.type === 'not_routable' && <TimerSinceComponent startTime={agentStatus?.startTimestamp} mutator={onMutateTimer} />}
    </>
  )

}

export default React.memo(IVRAgentInfo)
