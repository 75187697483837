import React, { useCallback } from 'react'

import { TimeUtils } from '@front/volcanion/utils'
import { useListener, useListenerSelector } from '@front/squirtle/IVR/hooks'

import Component from './component'

const getIconColor = (agentStatusType) => {
  switch (agentStatusType) {
    case 'routable':
      return 'green'
    case 'not_routable':
    case 'system':
      return 'orange'
    case 'offline':
    default:
      return 'red'
  }
}

const Container = () => {
  const listener = useListener('ivr', 'ivr', 'basic')
  const [agentName, agentStatus, agentStatusList] = useListenerSelector('ivr', 'ivr', 'basic',
    useCallback((l) => [
      l.getAgentName(),
      l.getAgentStatus(),
      l.getAgentStatusList()
    ], []))

  const iconColor = getIconColor(agentStatus?.type)

  const onAgentStatusChange = useCallback((value) => listener.setAgentStatus(value, agentStatus?.type === 'system'), [listener, agentStatus?.type])
  const onMutateTimer = useCallback((value) => TimeUtils.secondsToTime(value, 'mm:ss'), [TimeUtils.secondsToTime])

  const mergedProps = {
    agentName,
    agentStatus,
    agentStatusList,
    iconColor,
    onAgentStatusChange,
    onMutateTimer
  }
  return <Component {...mergedProps} />

}

export default Container
