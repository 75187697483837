import React from 'react'

import { Box, IconButton, Icon, Typography } from '@mui/material'

const IVRIncomingCall = (props) => {
  const {
    callerPhone,
    onAcceptCall,
    onRejectCall
  } = props

  return (
    <Box m={1} flexShrink={0} sx={{ display: 'flex', alignItems: 'center' }}>
      <IconButton aria-label="ivr" style={{ color: 'white' }} onClick={onAcceptCall} >
        <Icon>{'perm_phone_msg'}</Icon>
      </IconButton>
      <Box>{callerPhone}</Box>
      <IconButton aria-label="ivr" style={{ color: 'white' }} onClick={onRejectCall} >
        <Icon>{'phone_disabled'}</Icon>
      </IconButton>
      <Typography variant={'h4'} sx={{ ml: 1, color: 'white' }}> | </Typography>
    </Box>
  )

}

export default React.memo(IVRIncomingCall)
