import React from 'react'

import Container from './container'

import IVRRoot from '@front/squirtle/IVR'

const form = () => {
  return (
    <>
      <div id="ccpContainer" hidden />
      <IVRRoot name="ivr" options={{ container_id: 'ccpContainer' }}>
        <Container />
      </IVRRoot>
    </>
  )

}

export default form
