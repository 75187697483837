import { I18n } from '@front/volcanion'

class Callbacks {
  static onAcceptCallHandler(listener, openNotification) {
    return async function onAcceptCall() {
      try {
        await listener.acceptCall()
      }
      catch (err) {
        openNotification(I18n.t('ivr.error.acceptCall'), { variant: 'error' })
      }
    }
  }

  static onRejectCallHandler(listener, openNotification) {
    return async function onRejectCall() {
      try {
        await listener.rejectCall()
      }
      catch (err) {
        openNotification(I18n.t('ivr.error.rejectCall'), { variant: 'error' })
      }
    }
  }
}

export default Callbacks
