import React, { useCallback } from 'react'

import { hooks } from '@front/volcanion'
import { useListener, useListenerSelector } from '@front/squirtle/IVR/hooks'

import Component from './component'
import Callbacks from './callbacks'

const Container = () => {
  const { openNotification } = hooks.useNotification()
  const listener = useListener('ivr', 'ivr', 'basic')
  const [isConference] = useListenerSelector('ivr', 'ivr', 'basic',
    useCallback((l) => [
      l.isConference()
    ], [])
  )

  const onToggleActiveConnection = useCallback(Callbacks.onToggleActiveConnectionHandler(listener, openNotification), [listener, openNotification])
  const onConferenceConnection = useCallback(Callbacks.onConferenceConnectionHandler(listener, openNotification), [listener, openNotification])
  const onEndAgentConnection = useCallback(Callbacks.onEndAgentConnectionHandler(listener, openNotification), [listener, openNotification])

  const mergedProps = {
    isConference,
    onToggleActiveConnection,
    onConferenceConnection,
    onEndAgentConnection
  }
  return <Component {...mergedProps} />

}

export default Container
