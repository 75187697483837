import React from 'react'
import { Box, IconButton, Icon } from '@mui/material'

import { MenuSelectorComponent, TextPhoneInputComponent } from '@front/squirtle'

const IVRContact = (props) => {
  const {
    onNavigate,
    defaultLanguage,
    toggleMode,
    canDisplayContact,
    endpoints,
    dialableCountries,
    inputPhone,
    setInputPhone,
    selectedEndPoint,
    setSelectedEndPoint,
    onConnect,
    onTogglePhoneChange,
    onToggleContactChange
  } = props

  return (
    <>
      {canDisplayContact &&
        <IconButton style={{ color: 'white' }} onClick={onTogglePhoneChange}>
          <Icon>{toggleMode !== 'phone' ? 'add_circle' : 'cancel'}</Icon>
        </IconButton>
      }
      {!_.isEmpty(endpoints) && canDisplayContact &&
        <IconButton style={{ color: 'white' }} onClick={onToggleContactChange}>
          <Icon >{'contacts'}</Icon>
        </IconButton>
      }
      {toggleMode === 'phone' &&
        <Box>
          <TextPhoneInputComponent
            defaultCountry={defaultLanguage}
            value={inputPhone}
            onChange={setInputPhone}
            availableCountries={dialableCountries}
            sx={{
              input: { color: 'white' },
              '& .MuiInput-underline:after': {
                borderBottomColor: 'white'
              }
            }}
          />
        </Box>
      }
      {toggleMode === 'contact' && <Box>
        <MenuSelectorComponent
          name='selected_agent'
          options={endpoints}
          labelKeys={['name']}
          selectKeys={['endpointId']}
          onChange={setSelectedEndPoint}
          value={selectedEndPoint}
        />
      </Box>
      }
      {!!toggleMode &&
        <IconButton
          aria-label="ivr"
          style={{ color: 'green' }}
          onClick={onConnect}
          disabled={!inputPhone && !selectedEndPoint}
        >
          <Icon >{'call'}</Icon>
        </IconButton>
      }
      <IconButton aria-label="ivr" style={{ color: 'white' }} onClick={onNavigate} >
        <Icon >{'launch'}</Icon>
      </IconButton>
    </>
  )

}

export default React.memo(IVRContact)
