import React, { useCallback } from 'react'
import Component from './component'

import { hooks } from '@front/volcanion'

import { useListenerSelector, useListener } from '@front/squirtle/IVR/hooks'

import Callbacks from './callbacks'

const Container = () => {
  const { CPP_URL } = hooks.useRelayConstants(['CPP_URL'])
  const { openNotification } = hooks.useNotification()
  const listener = useListener('ivr', 'ivr', 'basic')
  const [{ isReady }, isLoggedIn, agentStatus, isMute, hasActiveFirstConnection, hasThirdPartyConnection, isAutoAccept] = useListenerSelector('ivr', 'ivr', 'basic',
    useCallback((l) => [
      l.getFullListenerStatus(),
      l.isLoggedIn(),
      l.getAgentStatus(),
      l.isMute(),
      l.hasActiveFirstConnection(),
      l.hasThirdPartyConnection(),
      l.isAutoAccept()
    ], [])
  )

  const canDisplayIconConnectButton = !!isReady && !!CPP_URL && !isLoggedIn
  const canDisplayIvrComponents = !!isReady && !!isLoggedIn

  const onMute = useCallback(Callbacks.onMuteHandler(listener, openNotification, isMute), [listener, openNotification, isMute])
  const onClick = useCallback(() => window.open(`${CPP_URL}/connect/ccp-v2#`, '_blank'), [CPP_URL])

  const mergedProps = {
    canDisplayIconConnectButton,
    canDisplayIvrComponents,
    agentStatus,
    isMute,
    onMute,
    hasActiveFirstConnection,
    hasThirdPartyConnection,
    isAutoAccept,
    onClick
  }

  return <Component {...mergedProps} />

}

export default Container
