import { I18n } from '@front/volcanion'

class Callbacks {
  static onMuteHandler(listener, openNotification, isMute) {
    return async function onMute() {
      try {
        if (isMute)
          listener.setAgentUnmute()
        else
          listener.setAgentMute()
      }
      catch (err) {
        openNotification(I18n.t('ivr.error.mute'), { variant: 'error' })
      }
    }
  }
}

export default Callbacks
