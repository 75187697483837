import { I18n } from '@front/volcanion'

class Callbacks {
  static onToggleActiveConnectionHandler(listener, openNotification) {
    return async function onToggleActiveConnection() {
      try {
        await listener.toggleActiveConnections()
      }
      catch (err) {
        openNotification(I18n.t('ivr.error.toggleActiveConnections'), { variant: 'error' })
      }
    }
  }

  static onConferenceConnectionHandler(listener, openNotification) {
    return async function onConferenceConnection() {
      try {
        await listener.conferenceConnections()
      }
      catch (err) {
        openNotification(I18n.t('ivr.error.conferenceConnections'), { variant: 'error' })
      }
    }
  }

  static onEndAgentConnectionHandler(listener, openNotification) {
    return async function onEndAgentConnection() {
      try {
        await listener.endAgentConnection()
      }
      catch (err) {
        openNotification(I18n.t('ivr.error.endAgentConnection'), { variant: 'error' })
      }
    }
  }
}

export default Callbacks
