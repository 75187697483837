import React, { useCallback } from 'react'

import { hooks } from '@front/volcanion'
import { useListener, useListenerSelector } from '@front/squirtle/IVR/hooks'

import Component from './component'
import Callbacks from './callbacks'

const Container = () => {
  const { openNotification } = hooks.useNotification()
  const listener = useListener('ivr', 'ivr', 'basic')
  const [callerPhone] = useListenerSelector('ivr', 'ivr', 'basic',
    useCallback((l) => [
      l.getPhoneNumber()
    ], []))

  const onAcceptCall = useCallback(Callbacks.onAcceptCallHandler(listener, openNotification), [listener, openNotification])
  const onRejectCall = useCallback(Callbacks.onRejectCallHandler(listener, openNotification), [listener, openNotification])

  const mergedProps = {
    callerPhone,
    onAcceptCall,
    onRejectCall
  }
  return <Component {...mergedProps} />

}

export default Container
