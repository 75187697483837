import React, { useCallback, useState } from 'react'

import { hooks } from '@front/volcanion'
import { useListenerSelector, useListener } from '@front/squirtle/IVR/hooks'


import Component from './component'
import Callbacks from './callbacks'


const Container = () => {
  const navigate = hooks.useNavigate()
  const [language] = hooks.useSetting('country_code')
  const { openNotification } = hooks.useNotification()

  const [toggleMode, setToggleMode] = useState(null)
  const [inputPhone, setInputPhone] = useState(null)
  const [selectedEndPoint, setSelectedEndPoint] = useState(null)

  const listener = useListener('ivr', 'ivr', 'basic')
  const [agentStatus, activeConnections, endpoints, dialableCountries, phoneNumber] = useListenerSelector('ivr', 'ivr', 'basic',
    useCallback((l) => [
      l.getAgentStatus(),
      l.getActiveConnections(),
      l.getEndpoints(),
      l.getDialableCountries(),
      l.getPhoneNumber()
    ], [])
  )

  const defaultLanguage = _.toLower(language)
  const maxConnections = 3
  const canDisplayContact = (agentStatus?.type === 'offline' || agentStatus?.type === 'system') && (!activeConnections || activeConnections?.length < maxConnections)

  const onConnect = useCallback(Callbacks.onConnectHandler(listener, openNotification, setToggleMode, toggleMode, inputPhone, selectedEndPoint),
    [listener, openNotification, toggleMode, inputPhone, selectedEndPoint])
  const onTogglePhoneChange = useCallback(Callbacks.onTogglePhoneChangeHandler(setInputPhone, setSelectedEndPoint, setToggleMode, inputPhone, selectedEndPoint, toggleMode),
    [inputPhone, selectedEndPoint, toggleMode])
  const onToggleContactChange = useCallback(Callbacks.onToggleContactChangeHandler(setSelectedEndPoint, setInputPhone, setToggleMode, selectedEndPoint, inputPhone, toggleMode),
    [selectedEndPoint, inputPhone, toggleMode])
  const onNavigate = useCallback(() => navigate('/order/create', { state: { phone: phoneNumber } }), [navigate, phoneNumber])

  const mergedProps = {
    onNavigate,
    defaultLanguage,
    toggleMode,
    canDisplayContact,
    endpoints,
    dialableCountries,
    inputPhone,
    setInputPhone,
    selectedEndPoint,
    setSelectedEndPoint,
    onConnect,
    onTogglePhoneChange,
    onToggleContactChange
  }
  return <Component {...mergedProps} />

}

export default Container
